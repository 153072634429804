<template>
  <button
    v-if="$auth.isAuthenticated"
    id="logout-button"
    class="flex items-center justify-center w-10 h-10 rounded-full hover:bg-blue-100 text-blue-600"
    type="button"
    @click="logout"
    :aria-label="$t('shared.logout')"
    :title="$t('shared.logout')"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27 30"
      width="27"
      height="30"
      aria-hidden="true"
      class="fill-current"
    >
      <path
        d="M0.1,17.8C-0.4,13,1.6,8.7,5,6c1.3-1.1,3.3-0.2,3.3,1.5l0,0c0,0.6-0.3,1.2-0.8,1.6c-2.4,1.9-3.8,5-3.5,8.4 c0.5,4.4,4,8,8.4,8.5c5.7,0.6,10.6-3.9,10.6-9.5c0-3-1.4-5.6-3.5-7.4c-0.5-0.4-0.8-1-0.8-1.6l0,0c0-1.7,1.9-2.6,3.2-1.6 c3.1,2.5,5,6.3,5,10.5c0,7.8-6.6,14-14.5,13.5C6,29.5,0.7,24.3,0.1,17.8z M13.5,0c-1.1,0-2,0.9-2,2v9.2c0,1.1,0.9,2,2,2s2-0.9,2-2 V2C15.5,0.9,14.6,0,13.5,0z"
      />
    </svg>
  </button>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
};
</script>
