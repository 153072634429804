<template>
  <div class="column__tag text-blue-600">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300.7 27"
      class="block"
    >
      <line
        v-if="line"
        class="stroke-current"
        stroke-width="3"
        stroke-dasharray="5,3"
        x1="200"
        y1="13.5"
        x2="300"
        y2="13.5"
      />
      <path
        class="fill-current"
        d="M188.4,0H14v0c-0.1,0-0.3,0-0.4,0C6.2,0,0.1,6,0.1,13.5C0.1,21,6.2,27,13.6,27c0.1,0,0.3,0,0.4,0v0h174.4l15.3-13.5L188.4,0 z"
      />
      <circle fill="#fff" opacity="0.2" cx="13.6" cy="13.5" r="9.5" />
      <text
        font-size="12"
        font-weight="600"
        dominant-baseline="middle"
        text-anchor="middle"
        fill="#fff"
        transform="translate(13 15)"
      >
        {{ index + 1 }}
      </text>
      <text
        font-size="14"
        dominant-baseline="middle"
        transform="matrix(1 0 0 1 31 15)"
        fill="#fff"
      >
        {{ $t(`milestones.${label}`) }}
      </text>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    index: {
      type: Number,
      default: 0,
    },
    line: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
