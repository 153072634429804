<template>
  <div
    class="flash fixed bottom-4 left-1/2 transform -translate-x-1/2 flex items-center justify-center w-11/12 md:w-auto bg-gray-800 p-4 text-white rounded-md pointer-events-none"
    role="alert"
  >
    <div
      class="flex items-center justify-center mr-3 p-2 h-8 w-8 rounded-full bg-gray-700"
    >
      <svg
        v-if="type === 'error'"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 30"
        width="30"
        height="30"
        aria-hidden="true"
        class="text-red-400"
      >
        <path
          class="fill-current"
          d="M25.6,4.4C22.7,1.6,19,0,15,0C6.8,0,0,6.7,0,15c0,8.3,6.7,15,15,15c8.2,0,15-6.7,15-15C30,11,28.4,7.3,25.6,4.4z M15,26.4
			C8.7,26.4,3.6,21.3,3.6,15c0-2.5,0.8-4.8,2.2-6.7l16,15.9C19.8,25.7,17.5,26.4,15,26.4z M24.2,21.7L8.3,5.8
			c1.9-1.4,4.3-2.2,6.7-2.2c6.3,0,11.4,5.1,11.4,11.4C26.4,17.5,25.7,19.8,24.2,21.7z"
        />
      </svg>
      <svg
        v-else-if="type === 'success'"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="23.3"
        viewBox="0 0 30 23.3"
        aria-hidden="true"
        class="text-green-400"
      >
        <path
          class="fill-current"
          d="M11.5,22.8c-0.3,0.3-0.7,0.4-1.1,0.4s-0.8-0.2-1.1-0.4l-8.6-8.6c-0.9-0.9-0.9-2.4,0-3.2l1.1-1.1C2.7,9,4.1,9,5,9.9l5.4,5.4
	L25,0.7c0.9-0.9,2.4-0.9,3.2,0l1.1,1.1c0.9,0.9,0.9,2.4,0,3.2L11.5,22.8z"
        />
      </svg>
      <svg
        v-else
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        aria-hidden="true"
        class="text-blue-400"
      >
        <g class="fill-current">
          <g>
            <path
              d="M15,30C6.7,30,0,23.3,0,15S6.7,0,15,0s15,6.7,15,15S23.3,30,15,30z M15,3.8C8.8,3.8,3.8,8.8,3.8,15s5,11.2,11.2,11.2
			s11.2-5,11.2-11.2S21.2,3.8,15,3.8z"
            />
          </g>
          <g>
            <g>
              <circle cx="14.8" cy="9.7" r="1.8" />
            </g>
            <g>
              <polygon
                points="17.5,22 16.6,22 13.4,22 12.5,22 12.5,19.7 13.4,19.7 13.4,15.6 12.6,15.6 12.6,13.2 13.4,13.2 16.1,13.2 
				16.6,13.2 16.6,19.7 17.5,19.7"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'info',
    },
  },
};
</script>

<style scoped>
.flash {
  animation: flash 4s ease-out;
  opacity: 0;
}

@keyframes flash {
  0% {
    opacity: 0;
    transform: translate(-50%, 100%);
  }

  5% {
    opacity: 1;
    transform: translate(-50%, 0);
  }

  95% {
    opacity: 1;
    transform: translate(-50%, 0);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, 100%);
  }
}
</style>
