<template>
  <div class="fixed inset-0 p-2 md:p-24 z-40" id="modal">
    <Overlay @click-overlay="$emit('close-modal')" />
    <div
      class="relative flex flex-col w-full md:max-w-screen-sm mx-auto max-h-full overflow-hidden bg-white rounded-xl z-50"
      role="modal"
      ref="dialog"
      aria-labelledby="modal-title"
    >
      <header class="px-8 pt-8 pb-4 border-b border-gray-100 font-medium">
        <slot name="header"></slot>
      </header>
      <div class="px-8 py-4 max-h-full overflow-y-scroll">
        <slot></slot>
      </div>
      <footer class="px-8 py-4 border-t border-gray-100">
        <button
          :class="[
            'rounded px-4 py-2 text-white',
            {
              'bg-red-600 hover:bg-red-700': destructive,
              'bg-blue-600 hover:bg-blue-700': !destructive,
            },
          ]"
          @click="$emit('modal-action')"
        >
          {{ actionLabel }}
        </button>
        <button
          class="close-button rounded px-4 py-2 hover:bg-gray-100 ml-2"
          type="reset"
          @click="$emit('close-modal')"
        >
          {{ $t('shared.cancel') }}
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
// import { AccessibleModal } from "accessible-modal";
import { Overlay } from './components';

// const modal = new AccessibleModal({
//   backdropClass: "overlay",
//   closeBtnClass: "close-button"
// });

export default {
  name: 'Modal',
  props: {
    actionLabel: {
      type: String,
    },
    destructive: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Overlay,
  },
};
</script>
