<template>
  <div class="bg-white rounded-xl overflow-hidden">
    <button
      :class="[
        'block w-full p-2 rounded-xl ring-inset',
        { 'rounded-b-none': cardPicker.length > 0 }
      ]"
      @touchend.prevent="handleOpen"
      @click.prevent="handleOpen"
      type="button"
    >
      <span
        :class="[
          'inline-block text-2xl text-gray-400 transition-transform duration-300 ease-out origin-center',
          {
            'transform rotate-45': cardPicker.length > 0
          }
        ]"
      >
        &plus;
      </span>
    </button>
    <div v-if="cardPicker.length > 0" class="pb-2">
      <button
        class="block w-full p-4 border-t border-gray-100 ring-inset"
        type="button"
        v-for="item in cardPicker"
        :key="item.id"
        @touchend.prevent="handleSelect(item)"
        @click.prevent="handleSelect(item)"
      >
        <IconHeading :id="item.id" :label="item.label" />
      </button>
    </div>
  </div>
</template>

<script>
import { IconHeading } from "../../../shared";

export default {
  props: {
    cards: Array,
    categories: Array
  },
  data() {
    return {
      cardPicker: []
    };
  },
  methods: {
    handleOpen() {
      this.cardPicker.length > 0
        ? (this.cardPicker = [])
        : this.chooseNewCard();
    },
    handleSelect(item) {
      this.$emit("add-card", item);
      this.cardPicker = [];
    },
    chooseNewCard() {
      const currentTitles = this.cards.map(({ label }) => label);

      const filteredCategories = this.categories.filter(function(e) {
        return this.indexOf(e.label) < 0;
      }, currentTitles);

      if (filteredCategories.length === 0) {
        return;
      } else if (filteredCategories.length === 1) {
        this.$emit("add-card", filteredCategories[0]);
      } else {
        this.cardPicker = filteredCategories;
      }
    }
  },
  components: {
    IconHeading
  }
};
</script>
