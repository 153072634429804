<template>
  <div class="column">
    <Header
      @delete="$emit('delete-column')"
      :title="title"
      :id="id"
      :index="index"
      :static="false"
    />
    <div class="column__wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { Header } from "./components";

export default {
  props: {
    title: String,
    id: String,
    index: Number
  },
  components: {
    Header
  }
};
</script>

<style lang="scss" scoped>
.column {
  width: $col-width;

  &__wrapper {
    padding: 1rem 0.75rem;
  }

  &__ghost {
    border-radius: $border-radius;
    background: transparentize($primary, 0.85);
  }
}
</style>
