<template>
  <Modal
    :actionLabel="$t('dialog.save_selection')"
    @modal-action="saveSelection"
    @close-modal="$emit('close-options')"
  >
    <template v-slot:header>
      <div class="flex items-center justify-between">
        <span class="mr-2 text-gray-500">
          {{
            $t(`categories.${category.label}.action_label`, {
              action: $t('shared.select'),
            })
          }}
        </span>
        <IconHeading :id="category.id" :label="category.label" />
      </div>
    </template>
    <ul>
      <li class="py-2" v-for="item in cardOptions" :key="item.id">
        <label>
          <input
            type="checkbox"
            :id="item.id"
            :name="item.label"
            :checked="item.checked"
            :value="item"
            v-model="selectedItems"
          />
          {{ $t(`options.${item.label}`) }}
        </label>
        <select
          v-if="item.options"
          class="block focus:outline-none focus:ring mt-1 rounded bg-gray-100"
          v-model="fibroscan"
        >
          <option v-for="option in item.options" :key="option" :value="option">
            {{ $t(`options.${option}`) }}
          </option>
        </select>
      </li>
    </ul>
  </Modal>
</template>

<script>
import { IconHeading, Modal } from '../../../shared';
import options from '../../../../data/options.json';

export default {
  props: {
    category: Object,
    checkedItems: Array,
  },
  data() {
    return {
      options,
      cardOptions: [],
      selectedItems: [],
      fibroscan: 'apri',
    };
  },
  mounted() {
    this.cardOptions = this.options.filter(
      option => option.category === this.category.id,
    );

    const checkedIds = this.checkedItems.map(item => {
      if (item.option) {
        this.fibroscan = item.option;
      }

      return item.id;
    });

    this.cardOptions = this.cardOptions.map(option => ({
      ...option,
      checked: checkedIds.includes(option.id) ? true : false,
    }));

    this.cardOptions.map(option => {
      if (option.checked) {
        this.selectedItems = [...this.selectedItems, option];
      }
    });
  },
  methods: {
    saveSelection() {
      const selectedItems = this.selectedItems.map(item => ({
        ...item,
        option: item.options ? this.fibroscan : null,
      }));

      this.$emit('save-selection', selectedItems);
    },
  },
  components: {
    IconHeading,
    Modal,
  },
};
</script>
