<template>
  <main role="main" class="roadmap-wrapper">
    <Canvas>
      <Board :currentColLength="columns.length" @add-column="addColumn">
        <draggable
          v-model="columns"
          ghost-class="column__ghost"
          handle=".column__tag"
          :animation="500"
          @start="setColumnDragging(true)"
          @end="setColumnDragging(false)"
          @choose="setColumnChosen(true)"
          @unchoose="setColumnChosen(false)"
        >
          <transition-group
            class="draggable__horizontal"
            type="transition"
            :name="columnDragging || columnChosen ? null : 'list'"
          >
            <Column
              v-for="(column, i) in columns"
              :key="column.id"
              :title="column.title"
              :id="column.id"
              :index="i"
              @delete-column="confirmColDeletion(i)"
            >
              <draggable
                v-model="column.cards"
                :animation="500"
                ghost-class="card__ghost"
                @start="setCardDragging(true)"
                @end="setCardDragging(false)"
              >
                <transition-group :name="cardDragging ? null : 'list'">
                  <Card
                    v-for="({ id, label, items }, j) in column.cards"
                    :key="id"
                    :category="{ id, label }"
                    :items="items"
                    @open-card-item-select="openCardItemSelect(i, id)"
                    @delete-card="column.cards.splice(j, 1)"
                    @delete-item="itemIndex => items.splice(itemIndex, 1)"
                  />
                </transition-group>
              </draggable>
              <AddCard
                v-if="column.cards.length < categories.length"
                :cards="column.cards"
                :categories="categories"
                @add-card="card => addCard(i, card)"
              />
            </Column>
          </transition-group>
        </draggable>
      </Board>
      <Portal to="dialog" v-if="optionsOpen">
        <Options
          :category="select.category"
          :checkedItems="select.items"
          @save-selection="saveSelection"
          @close-options="closeOptions"
        />
      </Portal>
      <Portal to="dialog" v-if="confirmItem">
        <Modal
          :actionLabel="$t('shared.delete')"
          destructive
          @modal-action="deleteColumn(confirmItem), (confirmItem = null)"
          @close-modal="confirmItem = null"
        >
          <template v-slot:header>
            {{ $t('dialog.delete_column.header') }}
          </template>
          <p>
            {{ $t('dialog.delete_column.body') }}
          </p>
        </Modal>
      </Portal>
    </Canvas>
    <ExampleGuideline
      v-if="peekedGuideline"
      :guidelines="peekedGuidelines"
      @set-peeked="setPeekedGuideline"
    />
  </main>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import draggable from 'vuedraggable';
import { uniqueId } from '../../utilities';
import {
  AddCard,
  Board,
  Canvas,
  Card,
  Column,
  ExampleGuideline,
  Options,
} from './components';
import { Modal } from '../shared';

import milestones from '../../data/milestones.json';
import categories from '../../data/categories.json';

import blueprint from '../../data/guidelines-blueprint.json';
import cta from '../../data/guidelines-cta.json';
import who from '../../data/guidelines-who.json';

export default {
  name: 'Roadmap',
  data() {
    return {
      confirmItem: null,
      optionsOpen: false,
      select: {},
      categories,
      milestones: milestones.slice(1),
      columns: [
        {
          id: 'column_first-visit',
          title: milestones[0].label,
          cards: [],
        },
      ],
    };
  },
  watch: {
    reset() {
      if (this.reset === true) {
        this.columns = [
          {
            id: 'column_first-visit',
            title: milestones[0].label,
            cards: [],
          },
        ];

        this.addNotification({
          type: 'success',
          message: this.$t('notifications.new_worksheet'),
        });

        this.toggleReset();
      }
    },
  },
  computed: {
    ...mapState('roadmaps', [
      'columnDragging',
      'columnChosen',
      'cardDragging',
      'peekedGuideline',
      'reset',
      'colToDelete',
      'colDelete',
    ]),
    peekedGuidelines() {
      return this.getGuidelinesArray(this.peekedGuideline) || null;
    },
  },
  methods: {
    ...mapMutations('roadmaps', [
      'setColumnDragging',
      'setColumnChosen',
      'setCardDragging',
      'setPeekedGuideline',
      'toggleReset',
    ]),
    ...mapActions({
      addNotification: 'notifications/addItem',
    }),
    getGuidelinesArray(guidelineString) {
      const guidelines = {
        blueprint,
        cta,
        who,
      };
      return guidelines[guidelineString];
    },
    addColumn() {
      this.columns.push({
        id: `column${uniqueId()}`,
        title: milestones[1].label,
        cards: [],
      });
    },
    deleteColumn(i) {
      this.columns.splice(i, 1);

      this.addNotification({
        type: 'success',
        message: this.$t('notifications.remove_column'),
      });
    },
    confirmColDeletion(i) {
      if (this.columns[i].cards.length > 0) {
        this.confirmItem = i;
      } else {
        this.deleteColumn(i);
      }
    },
    removeColumn(i) {
      if (this.columns[i].title === milestones[2].label) {
        this.milestones.push(milestones[2]);
      } // TODO check that this works properly

      this.deleteColumn(i);
      this.confirmItem = null;
    },
    addCard(i, category) {
      const { cards } = this.columns[i];

      cards.push({
        ...category,
        items: [],
      });
    },
    openCardItemSelect(i, id) {
      const cardIndex = this.columns[i].cards.findIndex(card => card.id === id);

      const card = this.columns[i].cards[cardIndex];

      const checkedOptions = card.items.map(option => ({
        ...option,
        checked: true,
      }));

      this.optionsOpen = true;

      this.select.colIndex = i;
      this.select.cardIndex = cardIndex;
      this.select.category = {
        id: card.id,
        label: card.label,
      };
      this.select.items = checkedOptions;
    },
    addCardItems(items) {
      const col = this.columns[this.select.colIndex];
      col.cards[this.select.cardIndex].items = items;
    },
    saveSelection(selection) {
      this.addCardItems(selection);
      this.closeOptions();
    },
    closeOptions() {
      this.optionsOpen = false;
      this.select = {};
    },
  },
  components: {
    AddCard,
    Board,
    Canvas,
    Card,
    Column,
    ExampleGuideline,
    draggable,
    Modal,
    Options,
  },
};
</script>

<style lang="scss">
.roadmap-wrapper {
  position: relative;
  grid-area: roadmap;
  background: $light;
  border-top: 1px solid $light;

  @media (orientation: portrait) {
    border-bottom: 1px solid $light;
  }

  @media (orientation: landscape) {
    border-top-left-radius: $border-radius;
    border-left: 1px solid $light;
  }
}

.draggable__horizontal {
  display: flex;
  align-items: flex-start;
  margin-left: -0.75rem;
}
</style>
