<template>
  <div class="flex items-center px-4">
    <div
      class="flex items-center justify-center h-8 w-8 mr-2 bg-blue-100 p-2 rounded-full text-blue-600"
    >
      <svg
        v-if="id === 1"
        viewBox="0 0 30 30"
        width="20"
        height="20"
        aria-hidden="true"
        class="fill-current"
      >
        <path
          d="M15.9,0.4c-0.4-0.5-1.3-0.5-1.7,0c-0.4,0.4-9.5,10.7-9.5,19.2C4.6,25.3,9.3,30,15,30s10.4-4.7,10.4-10.4 C25.4,11.1,16.2,0.8,15.9,0.4z M17.9,20.2h-1.7v1.7c0,0.6-0.5,1.2-1.2,1.2s-1.2-0.5-1.2-1.2v-1.7h-1.7c-0.6,0-1.2-0.5-1.2-1.2 c0-0.6,0.5-1.2,1.2-1.2h1.7v-1.7c0-0.6,0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2v1.7h1.7c0.6,0,1.2,0.5,1.2,1.2C19,19.7,18.5,20.2,17.9,20.2 z"
        />
      </svg>
      <svg
        v-if="id === 2"
        viewBox="0 0 30 30"
        width="20"
        height="20"
        aria-hidden="true"
        class="fill-current"
      >
        <g>
          <path
            d="M12.5,17c0-0.6-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h8C12,18,12.5,17.6,12.5,17z"
          />
          <path
            d="M26.3,28.4l-3-4c-0.2-0.3-0.5-0.4-0.8-0.4h-1.4c2-1.7,3.4-4.2,3.4-7c0-3.5-2-6.5-4.9-8l-1.5,1.5
		c2.6,1,4.4,3.6,4.4,6.5c0,3.9-3.1,7-7,7c-2.8,0-5.2-1.6-6.3-4H7c0.6,1.6,1.6,3,2.8,4H8.5c-0.3,0-0.6,0.1-0.8,0.4l-3,4 c-0.5,0.7,0,1.6,0.8,1.6h20C26.3,30,26.8,29.1,26.3,28.4z"
          />
          <path
            d="M11.6,12.7c0.4,0.4,1,0.4,1.4,0l8.1-8.1c0.4-0.4,0.4-1,0-1.4l-2.9-2.9c-0.4-0.4-1-0.4-1.4,0L8.8,8.4 c-0.4,0.4-0.4,1,0,1.4L11.6,12.7z"
          />
        </g>
      </svg>
      <svg
        v-if="id === 3"
        viewBox="0 0 30 30"
        width="20"
        height="20"
        aria-hidden="true"
        class="fill-current"
      >
        <g>
          <path
            d="M5.1,13.9c0.5,0.3,1.2,0.5,1.8,0.4c1.8-0.2,2.5-1.3,2.7-2.6c0.2,0.3,0.4,0.6,0.6,0.8c1.4,1.6,3.2,2.7,4.9,2.7 c2,0,4.1-1.6,5.4-3.7c0,1.3,0.4,2.4,1.9,2.7c2.1,0.5,3.4-2,3.4-2l0.4-0.7l-0.7-0.2c-2.1-0.6-2.7-2.6-3.2-4.6 c-0.7-2.6-1.4-5.2-4.7-6.2C16.7,0.2,15.9,0,15.1,0c-1.7,0-3.6,0.9-5,2.3c-0.5,0.5-0.9,1-1.2,1.5L8.8,3.9C8.6,4.3,8.5,4.7,8.4,5 c0,0.1,0,0.1-0.1,0.2c0,0.1-0.1,0.2-0.1,0.4c0,0.1-0.1,0.3-0.1,0.4C8.1,6.3,8,6.5,8,6.8C7.9,7.4,7.8,8,7.5,8.6 C7.2,9.3,6.8,10,6.3,10.5s-1.1,0.9-1.8,1.1l-0.6,0.2L4,12.4C4.2,13.1,4.6,13.6,5.1,13.9z M15.1,14c-1.3,0-2.8-0.9-3.9-2.2 c-0.6-0.7-1.1-1.5-1.5-2.4c0-0.7,0-1.3,0.1-1.3c1.8-0.6,3.8-1.8,5.1-3.2c0.4-0.4,0.7-0.8,0.9-1.2C16,4,16.3,4.4,16.5,4.8 c1.1,1.4,2.6,2.5,4,3.1C20.1,11,17.6,14,15.1,14z"
          />
          <path
            d="M20.7,14.6c0,0.4,0,0.9,0.2,1.4c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.3,0.3,0.4c0.6,0.8,1.5,2,1.3,3 c0,0.2-0.1,0.3-0.2,0.5l0,0c0.2,0.4,0.1,0.8,0,1.1c-0.1,0.4-0.4,0.7-0.8,0.8c-0.4,0.2-0.8,0.1-1.1,0c-0.4-0.1-0.7-0.4-0.8-0.8 c-0.2-0.4-0.1-0.8,0-1.1c0.1-0.4,0.4-0.7,0.8-0.8c0.4-0.2,0.8-0.1,1.1,0c0.2,0.1,0.4,0.2,0.5,0.4l0,0c0.2-0.7-0.3-1.5-0.6-2 c-0.2-0.3-0.4-0.5-0.6-0.8c-0.1-0.2-0.2-0.3-0.4-0.5c-0.1-0.1-0.2-0.2-0.2-0.4c-0.2-0.5-0.3-1-0.3-1.5
		C20.4,14.6,20.5,14.6,20.7,14.6c-0.1,0-0.3,0-0.4,0s-0.2,0-0.4,0h-0.3l-0.2,0.3c-1.6,2.1-3,3.1-4.4,3c-1.4-0.1-2.9-1.2-4.3-3.1
		l-0.2-0.2h-0.3c-0.6-0.1-1-0.1-1.3-0.1C8.8,15,8.8,15.7,9,16.3c0.2,0,0.5,0,0.7,0.2c0.3,0.2,0.5,0.4,0.7,0.8s0.6,1,0.9,1.6
		c0.4,0.8,0.9,1.5,1,1.8l-0.1,0.1c0,0,0,0.1,0.1,0.1V21c0.2,0.4,0.4,0.9,0.4,1.3c0,0.6-0.3,0.9-0.8,1.2v0.1c0,0.2-0.1,0.3-0.3,0.3
		c-0.1,0.1-0.3,0.1-0.4,0c-0.2,0-0.3-0.1-0.3-0.3c-0.1-0.1-0.1-0.3,0-0.4c0-0.2,0.1-0.3,0.3-0.3c0.1-0.1,0.3-0.1,0.4,0
		c0.2,0,0.3,0.1,0.3,0.3l0,0c0.3-0.2,0.6-0.4,0.6-0.9c0-0.4-0.2-0.9-0.4-1.2l0,0c0,0,0-0.1-0.1-0.1l-0.1,0.1c-0.2-0.3-0.6-1-1-1.8
		c-0.3-0.6-0.7-1.2-0.9-1.6c-0.1-0.3-0.3-0.5-0.5-0.6C9.4,17,9.2,17,9.1,17s-0.3-0.1-0.4,0s-0.2,0.3-0.3,0.6C8.2,18,8,18.7,7.8,19.4
		c-0.2,0.9-0.5,1.7-0.6,2H7.1v0.1v0.1C7,21.8,7,21.9,7,22.1c0,0.3-0.1,0.6,0,0.8c0.1,0.3,0.4,0.5,0.7,0.6l0,0c0-0.2,0.1-0.3,0.3-0.3
		c0.2,0,0.3-0.1,0.4,0c0.2,0,0.3,0.1,0.3,0.3c0.1,0.1,0.1,0.3,0,0.4c0,0.2-0.1,0.3-0.3,0.3c-0.1,0.1-0.3,0.1-0.4,0
		c-0.2,0-0.3-0.1-0.3-0.3v-0.1c-0.4-0.1-0.9-0.3-1-0.8c-0.1-0.3-0.1-0.7,0-1c0-0.2,0.1-0.4,0.1-0.5v-0.1v-0.1H6.7
		c0.1-0.3,0.3-1.2,0.6-2c0.2-0.7,0.4-1.4,0.5-1.8s0.3-0.6,0.5-0.8c0.1-0.1,0.3-0.2,0.4-0.3c-0.1-0.6-0.2-1.3-0.1-1.9
		c-0.4,0.1-0.7,0.4-1.3,0.9l0,0c-1.7,1.6-2.5,3.2-2.9,4.9C4,22,4,23.7,4,25.7V26l0.2,0.2c1.5,1.2,3.2,2.2,5,2.9c1.8,0.6,3.7,1,5.8,1
		c2,0,4-0.3,5.8-1c1.8-0.7,3.5-1.6,5-2.9L26,26v-0.3v-0.2c0-3.8,0-6.6-2.9-9.7C22,14.7,21.3,14.6,20.7,14.6z"
          />
        </g>
      </svg>
    </div>
    <div class="text-sm uppercase font-semibold">
      {{ $t(`categories.${label}.label`) }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    label: String
  }
};
</script>
