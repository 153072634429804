var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.path)?_c('router-link',{class:[
    'flex items-center justify-center w-10 h-10 rounded-full focus:outline-none focus:ring bg-white hover:text-gray-100',
    {
      'bg-blue-600 hover:bg-blue-700': _vm.primary
    }
  ],attrs:{"to":_vm.path,"aria-label":_vm.ariaLabel ? _vm.ariaLabel : _vm.$t('shared.close')}},[_c('span',{class:[
      'inline-block text-xl',
      {
        'text-white': _vm.primary,
        'text-gray-400': !_vm.primary
      }
    ]},[_vm._v(" "+_vm._s(_vm.plus ? "+" : "×")+" ")])]):_c('button',{class:[
    'flex items-center justify-center w-10 h-10 rounded-full bg-white hover:text-gray-100',
    {
      'bg-blue-600 hover:bg-blue-700': _vm.primary
    }
  ],attrs:{"type":_vm.type,"aria-label":_vm.ariaLabel ? _vm.ariaLabel : _vm.$t('shared.close')},on:{"click":function($event){return _vm.$emit('fab-click')}}},[_c('span',{class:[
      'inline-block text-xl',
      {
        'text-white': _vm.primary,
        'text-gray-400': !_vm.primary
      }
    ]},[_vm._v(" "+_vm._s(_vm.plus ? "+" : "×")+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }