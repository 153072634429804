<template>
  <li
    :class="[
      'flex justify-between items-center border-t border-gray-100 text-gray-600 pl-4',
      { 'bg-green-100 text-green-900': highlight },
    ]"
  >
    <p class="py-2 whitespace-normal">{{ label }}</p>
    <button
      class="px-4 py-2 rounded-full ring-inset"
      type="button"
      @touchend.prevent="$emit('delete-item')"
      @click.prevent="$emit('delete-item')"
    >
      <span :class="['text-gray-400 text-xl', { 'text-green-900': highlight }]">
        &times;
      </span>
    </button>
  </li>
</template>

<script>
export default {
  props: {
    label: String,
    highlight: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
