<template>
  <header class="relative mt-4 -mr-3 pl-3 cursor-move">
    <Tag :label="title" :index="index" :line="!columnChosen" />
    <button
      v-if="deleteable && !columnChosen"
      @touchend.prevent="handleClick"
      @click.prevent="handleClick"
      class="absolute top-0 left-52 h-full w-8 rounded-full ring-inset ring-blue-400"
      type="button"
      :aria-label="$t('dialog.delete_column.header')"
    >
      <span class="text-white text-xl">
        &times;
      </span>
    </button>
  </header>
</template>

<script>
import { mapState } from "vuex";
import { FIRST_COL_ID } from "../../../../../../utilities";
import { Tag } from "../../../../../shared";

export default {
  props: {
    title: String,
    id: String,
    index: Number
  },
  data() {
    return {
      open: false
    };
  },
  computed: {
    ...mapState("roadmaps", ["columnDragging", "columnChosen"]),
    deleteable() {
      return this.id !== FIRST_COL_ID;
    }
  },
  methods: {
    handleClick() {
      if (this.columnDragging) {
        return null;
      } else {
        this.$emit("delete");
      }
    }
  },
  components: {
    Tag
  }
};
</script>
