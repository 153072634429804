<template>
  <header class="header">
    <NewButton @new-click="(confirmNew = true), setPeekedGuideline(null)">
      {{ $t('shared.new') }}
    </NewButton>
    <div class="relative w-full h-full -mr-2 ml-2">
      <div
        class="absolute top-0 left-0 w-full h-full flex flex-nowrap justify-end"
      >
        <div class="flex overflow-auto">
          <button
            v-for="item in guidelines"
            :key="item"
            :class="[
              'bg-blue-600 px-4 text-white ring-inset ring-blue-400',
              { ring: peekedGuideline === item },
            ]"
            type="button"
            @click="toggleGuideline(item)"
          >
            <span class="block text-xs text-blue-200">
              {{ $t('guidelines.guidelines') }}
            </span>
            <span class="whitespace-nowrap">{{
              $t(`guidelines.${item}`)
            }}</span>
          </button>
        </div>
      </div>
      <div class="slider__shade"></div>
    </div>
    <Portal to="dialog" v-if="confirmNew">
      <ConfirmNew @close="confirmNew = false" />
    </Portal>
  </header>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { NewButton } from '../../shared';
import { ConfirmNew } from './components';

export default {
  data() {
    return {
      guidelines: ['blueprint', 'cta', 'who'],
      confirmNew: false,
    };
  },
  computed: {
    ...mapState('roadmaps', ['peekedGuideline']),
  },
  methods: {
    ...mapMutations('roadmaps', ['setPeekedGuideline']),
    toggleGuideline(guideline) {
      this.peekedGuideline && this.peekedGuideline === guideline
        ? this.setPeekedGuideline(null)
        : this.setPeekedGuideline(guideline);
    },
  },
  components: {
    ConfirmNew,
    NewButton,
  },
};
</script>

<style lang="scss" scoped>
.header {
  grid-area: header;
  @include flex-apart;
  padding: 0 2rem;

  @media (max-width: $mobile) {
    padding: 0 $small-padding;
  }
}

.slider {
  // position: relative;
  // height: 100%;
  // width: 100%;
  // margin-right: -1 * $small-padding;
  // margin-left: $small-padding;

  // &__scroll {
  //   display: flex;
  //   overflow: auto;
  // }

  // &__button {
  //   // background: $primary;
  //   // padding: 0 1rem;
  //   // color: #fff;
  //   // border-bottom: 3px solid $primary;

  //   // &.active {
  //   //   border-bottom: 3px solid $blue;
  //   // }

  //   &--title {
  //     display: block;
  //     font-size: 0.6rem;
  //     text-transform: uppercase;
  //     color: transparentize(#fff, 0.25);
  //   }
  // }

  &__shade {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: $small-padding;
    background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
    z-index: 2;
  }
}
</style>
