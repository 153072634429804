<template>
  <nav class="nav" role="navigation" aria-label="navigation">
    <div class="nav__section">
      <NavLink
        v-for="loc in locales"
        :key="loc"
        :label="loc.toUpperCase()"
        :active="$i18n.locale === loc"
        @link-click="switchLocale(loc)"
      />
    </div>
    <div class="nav__section">
      <LogoutButton />
    </div>
  </nav>
</template>

<script>
import { LogoutButton, NavLink } from "./components";
export default {
  data() {
    return {
      locales: ["en", "fr"]
    };
  },
  methods: {
    switchLocale(locale) {
      this.$i18n.locale = locale;
      document.documentElement.setAttribute("lang", locale);
    }
  },
  components: {
    LogoutButton,
    NavLink
  }
};
</script>

<style lang="scss" scoped>
.nav {
  grid-area: nav;
  @include flex-apart;

  @media (orientation: portrait) {
    align-items: stretch;
  }

  @media (orientation: landscape) {
    flex-direction: column;
    padding: 1rem 0;
  }

  &__section {
    @include flex-center;

    @media (orientation: landscape) {
      flex-direction: column;
      width: 100%;
      margin: 1rem 0;
    }

    @media (orientation: portrait) {
      flex-direction: row;
      display: flex;
      margin: 0 1rem;
    }
  }
}
</style>
