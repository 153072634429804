<template>
  <div class="board">
    <slot></slot>
    <div class="board__button">
      <Fab
        v-if="currentColLength < 10 && !columnDragging"
        :aria-label="$t('dialog.add_column')"
        primary
        plus
        @fab-click="handleOpen"
      />
    </div>
    <div
      :class="['board__padding', { collapsed: currentColLength >= 10 }]"
      ref="rightEdge"
    ></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Fab } from "../../../shared";

export default {
  props: {
    currentColLength: Number
  },
  computed: {
    ...mapState("roadmaps", ["columnDragging"])
  },
  methods: {
    handleOpen() {
      this.$emit("add-column");
      this.scrollIntoView();
    },
    scrollIntoView() {
      setTimeout(() => {
        this.$refs.rightEdge.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  },
  components: {
    Fab
  }
};
</script>

<style lang="scss" scoped>
.board {
  @include pos-cover;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  user-select: none;
  white-space: nowrap;
  overflow-x: auto;
  padding-left: $large-padding;

  @media (max-width: $mobile) {
    padding-left: $small-padding;
  }

  &__button {
    margin-top: 0.8rem;
    z-index: 3;
  }

  &__padding {
    width: $large-padding;
    height: $large-padding;
    flex-shrink: 0;

    &.collapsed {
      width: 1px;
    }
  }
}
</style>
