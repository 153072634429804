<template>
  <button
    :class="[
      'nav-link flex items-center justify-center hover:bg-blue-100 ring-inset',
      { 'bg-blue-100': active }
    ]"
    @click="$emit('link-click')"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.nav-link {
  @media (orientation: portrait) {
    height: 100%;
    min-width: $button-height;
  }

  @media (orientation: landscape) {
    width: 100%;
    min-height: $button-height;
  }
}
</style>
