<template>
  <div class="dashboard">
    <Logo />
    <Header />
    <Nav />
    <Roadmap />
    <Notifications />
    <PortalTarget name="dialog" />
  </div>
</template>

<script>
import { Header, Logo, Nav } from "./Layout";
import { Notifications } from "./Notifications";
import { Roadmap } from "./Roadmaps";

export default {
  name: "Dashboard",
  components: {
    Header,
    Logo,
    Nav,
    Notifications,
    Roadmap
  }
};
</script>

<style lang="scss">
.dashboard {
  display: grid;
  max-height: 100vh;

  @media (orientation: portrait) {
    grid-template-areas:
      "header"
      "roadmap"
      "nav";

    grid-template-columns: 1fr;
    grid-template-rows: $width 1fr $width;
  }

  @media (orientation: landscape) {
    grid-template-areas:
      "logo header"
      "nav roadmap";

    grid-template-columns: $width auto;
    grid-template-rows: $width auto;
  }
}
</style>
