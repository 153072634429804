<template>
  <div class="notifications">
    <transition-group name="notification" mode="out-in">
      <toast
        v-for="{ message, type } in notifications"
        :type="type"
        :key="message"
      >
        {{ message }}
      </toast>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Toast from './Toast';
export default {
  computed: {
    ...mapGetters({
      notifications: 'notifications/notifications',
    }),
  },
  components: {
    Toast,
  },
};
</script>
