<template>
  <div class="bg-white rounded-xl mb-4">
    <header class="flex justify-between items-center py-4">
      <IconHeading :id="category.id" :label="category.label" />
      <button
        class="px-4 py-2 rounded-full ring-inset"
        type="button"
        @touchend.prevent="$emit('delete-card')"
        @click.prevent="$emit('delete-card')"
      >
        <span class="text-gray-400 text-xl">
          &times;
        </span>
      </button>
    </header>
    <div>
      <transition-group tag="ul" name="list">
        <ListItem
          v-for="({ id, label, option, highlight }, k) in items"
          :key="id"
          :label="
            `${$t(`options.${label}`)}${
              option ? ` (${$t(`options.${option}`)})` : ''
            }`
          "
          :highlight="highlight"
          @delete-item="$emit('delete-item', k)"
        />
      </transition-group>
    </div>
    <footer class="border-t border-gray-100 ">
      <button
        class="flex items-center w-full p-4 rounded-b-xl ring-inset text-left text-gray-400 leading-none"
        type="button"
        @touchend.prevent="$emit('open-card-item-select')"
        @click.prevent="$emit('open-card-item-select')"
        ref="addButton"
      >
        <span class="mr-1 leading-none align-top">
          &plus;
        </span>
        <span>
          {{
            $t(`categories.${category.label}.action_label`, {
              action: $t("shared.add")
            })
          }}
        </span>
      </button>
    </footer>
  </div>
</template>

<script>
import { ListItem } from "./components";
import { IconHeading } from "../../../shared";

export default {
  props: {
    items: Array,
    category: Object
  },
  mounted() {
    setTimeout(() => {
      this.$refs.addButton.scrollIntoView({
        behavior: "smooth",
        block: "end"
      });
    }, 500);
  },
  components: {
    IconHeading,
    ListItem
  }
};
</script>
