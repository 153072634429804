<template>
  <router-link
    v-if="path"
    :to="path"
    :aria-label="ariaLabel ? ariaLabel : $t('shared.close')"
    :class="[
      'flex items-center justify-center w-10 h-10 rounded-full focus:outline-none focus:ring bg-white hover:text-gray-100',
      {
        'bg-blue-600 hover:bg-blue-700': primary
      }
    ]"
  >
    <span
      :class="[
        'inline-block text-xl',
        {
          'text-white': primary,
          'text-gray-400': !primary
        }
      ]"
    >
      {{ plus ? "&plus;" : "&times;" }}
    </span>
  </router-link>
  <button
    v-else
    @click="$emit('fab-click')"
    :type="type"
    :aria-label="ariaLabel ? ariaLabel : $t('shared.close')"
    :class="[
      'flex items-center justify-center w-10 h-10 rounded-full bg-white hover:text-gray-100',
      {
        'bg-blue-600 hover:bg-blue-700': primary
      }
    ]"
  >
    <span
      :class="[
        'inline-block text-xl',
        {
          'text-white': primary,
          'text-gray-400': !primary
        }
      ]"
    >
      {{ plus ? "&plus;" : "&times;" }}
    </span>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "button"
    },
    ariaLabel: {
      type: String
    },
    plus: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    },
    path: {
      type: Object,
      required: false
    }
  }
};
</script>
