<template>
  <div class="absolute inset-0 p-4 z-20 overflow-y-auto">
    <div class="bg-white rounded-xl border border-gray-100">
      <div class="flex flex-wrap p-4 items-start">
        <div
          v-for="(column, i) in guidelines"
          :key="column.id"
          :title="column.title"
          :id="column.id"
          :index="i"
          class="w-full md:w-1/3 max-w-screen-sm pr-4"
        >
          <Tag :label="column.title" :index="i" :line="false" />
          <div
            v-for="{ id, label, items } in column.cards"
            :key="id"
            class="my-8"
          >
            <IconHeading :id="id" :label="label" />
            <ul class="mt-4">
              <li
                v-for="option in items"
                :key="option.id"
                :class="[
                  'px-4 py-2 border-t border-gray-100 text-gray-500',
                  { 'bg-green-100 text-green-900': option.highlight },
                ]"
              >
                {{ $t(`options.${option.label}`, { option: null }) }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="flex justify-end p-4 border-t border-gray-100">
        <CloseButton @close-click="$emit('set-peeked', null)">
          {{ $t('shared.close') }}
        </CloseButton>
      </div>
    </div>
  </div>
</template>

<script>
import { CloseButton, IconHeading, Tag } from '../../../shared';

export default {
  name: 'ExampleGuideline',
  props: {
    guidelines: {
      type: Array,
      required: true,
    },
  },
  components: {
    CloseButton,
    IconHeading,
    Tag,
  },
};
</script>
