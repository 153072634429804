<template>
  <Modal
    :actionLabel="$t('shared.new')"
    destructive
    @modal-action="toggleReset(), $emit('close')"
    @close-modal="$emit('close')"
  >
    <template v-slot:header>
      {{ $t("dialog.clear_worksheet.header") }}
    </template>
    <p>
      {{ $t("dialog.clear_worksheet.body") }}
    </p>
  </Modal>
</template>

<script>
import { mapMutations } from "vuex";
import { Modal } from "../../../../shared";

export default {
  name: "ConfirmNew",
  methods: {
    ...mapMutations("roadmaps", ["toggleReset"])
  },
  components: {
    Modal
  }
};
</script>
